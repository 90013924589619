<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";

  #edui_fixedlayer {
    z-index: 2000 !important;
  }
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-input {
    width: 60%;
    margin-right: 10px;
  }

  /deep/.el-input__inner {
    height: 35px;
    background-color: #FFF9EB;
  }

  /deep/.el-form-item {
    width: 100%;
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  /deep/.el-form-item__error {
    position: absolute !important;
    top: 20%;
    left: 60%;
    text-align: center;
    width: 120px;
    height: 21px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 21px;
    z-index: 99;
  }

  .check-distri span {
    width: 72px;
    display: inline-block;
  }

  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #FFF9EB;
  }

  .h30 {
    line-height: 13px;
  }

  .content-mould {
    width: 100%;
    padding: 5px;
    height: 400px;
    overflow-y: auto;
  }

  .msgVal.selectShow .msgValInput {
    padding-top: 8px;

  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import ExamHeader from "./exam-header";
  import PrintRules from "@/views/pages/admin/components/printRules.vue";
  import $ from "jquery"
  import {
    easyList,
    SecondEasyList
  } from "@/api/admin/base/mainType.js"
  import {
    addExamRecord,
    editExamRecord,
    getExamDetails
  } from "@/api/admin/exam/examRecord.js"
  import {
    getNoticeList,
    addNoticeTemplate,
  } from "@/api/admin/base/mould.js"
  import {
    newsList,

  } from "@/api/web/news.js";
  /**
   * 考试报名--基本信息
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      ExamHeader,
      PrintRules
    },
    data() {
      return {
        title: "考试报名信息管理/",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "考试信息",
            active: true
          }
        ],
        chooseModel: {},
        checkBox: [],
        chooseNews: {},
        showmodal: false,
        showmodal2: false,
        showmodal3: false,
        tags: [],
        rules: {
          ksjc: [{
            required: true,
            message: '请输入考试简称',
            trigger: 'blur'
          }],
          kmxzfs: [{
            required: true,
            message: '请选择科目选择方式',
            trigger: 'blur'
          }]
        },
        ksbmbh: "",
        sid: "",
        flbm: "",
        addForm: {
          mbbm: "",
          mblb: "",
          glfs: "",
          gllbdm: "",
          gllbmc: "",
          mbmc: "",
          qyzt: true,
          mbnr: "",
        },
        ksmc: "",
        examForm: {
          ksbmbh: "",
          lbbm: "",
          lbmc: "",
          flbm: "",
          flmc: "",
          ksmc: "",
          ksjc: "",
          ksnf: "",
          lsksxxmc: "",
          lsksxxh: "",
          kslxh: "",
          kslxmc: "",
          kmxzfs: "",
          bkdwbt: true,
          bkzwbt: true,
          sfsczp: true,
          sfgdks: false,
          sfjmks: true,
          yxcbm: false,
          xydw: "",
          kwggh: "",
          kwggmc: "",
          kscnsh: "",
          kscnsmc: "",
          zyjbpd: 1,
          zpysyq: 1,
          zpzdsh: true,
          xlzdsh: "0",
          sfgzcn: true,
          gzcntzh: "",
          gzcntzmc: "",
          bmtzh: "",
          bmtzmc: "",
          jhxqh: "",
          jhxqmc: "",
          bmbsmh: "",
          bmbsmmc: "",
          zkzsmh: "",
          zkzsmmc: "",
          qrbzysxh: "",
          qrbzysxmc: "",

        },
        mc: "",
        dm: "",
        years: [],
        newsList: [],
        classList: [],
        secondList: [],
        mouldList: [],
        showCheckBox: false,
        pageData: { //模板分页
          pageNum: 1,
          pageSize: 10,
          total: 0
        },
        pageData2: { //公告分页
          pageNum: 1,
          pageSize: 10,
          total: 0,
          keyword: "",
        },
        module: "KSTSMB",
      };
    },
    methods: {
      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      //addNew
      addNew(){
        this.showmodal3=true
        this.getBm(this.module, "mb").then((e) => {
          this.addForm.mbbm = e;
        });
      },
      // 添加模板
      addMould() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        addNoticeTemplate(formDate).then(res => {
          if (res.status) {
            this.$notify({
              type: 'success',
              message: '数据操作成功!',
              duration: 3000,
            })
            this.showmodal3 = false
            this.getMouldList(this.flbm)
            this.addForm = {}

          }

        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData2.pageNum = 1; //当前页
        this.getNewsList() //渲染数据的函数
      },
      // 新闻公告
      getNewsList() {
        newsList(this.pageData2).then(res => {
          if (res.status) {
            this.newsList = res.data
            this.total = res.total
          }

        });
      },
      // 模板

      getMouldList(params) {
        getNoticeList({
          ...this.pageData,
          gllbdm: params,
          qyzt: 1
        }).then(res => {
          if (res.status) {
            this.mouldList = res.data
            this.pageData.total = res.total
            if (this.examForm.ksxzh) {
              let list = this.examForm.ksxzh.split(',')
              this.checkBox = []
              list.forEach(v => {
                let data = this.mouldList.filter(x => {
                  return v == x.mbbm
                })[0]
                if (data) {
                  this.checkBox.push(data)
                }
              })
            }


          }
        })
      },
      //添加报名信息
      addExamForm() {
        let formDate = this.examForm
        if (formDate.ksnf && formDate.ksjc) {
          if (formDate.kmxzfs) {
            addExamRecord(formDate).then(res => {
              if (res.status) {
                this.sid = res.data.sid
                this.ksbmbh = res.data.ksbmbh
                if (!this.ksbmbh) {
                  return
                } else {
                  this.success()
                }
              }

            })
          } else {
            this.$message({
              type: "warning",
              message: "请选择报名方式！",
            });
          }


        } else {
          this.$message({
            type: "warning",
            message: "请先添加考试",
          });
        }

      },
      editExamForm() {
        let formDate = this.examForm
        editExamRecord({
          ...formDate,
          sid: this.sid
        }).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      // 获取详情
      getDetail(ksbmbh) {
        getExamDetails(ksbmbh).then(res => {
          if (res.status) {
            this.examForm = res.data
            this.flbm = res.data.flbm
            this.ksmc = res.data.ksmc
            // tags初始化
            if (res.data.ksxzh) {
              let label = res.data.ksxz.split(",")
              let value = res.data.ksxzh.split(",")
              label.forEach((item, index) => {
                this.tags.push({
                  label: label[index],
                  value: value[index]
                })
              })

            }
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.ksmc = this.ksmc ? this.ksmc : this.examForm.ksmc
        let ksbmbh = this.ksbmbh ? this.ksbmbh : this.examForm.ksbmbh
        // this.$refs.header.getUrl('/admin/examEnrollMould')
        window.location.href = "/admin/examEnrollMould?sid=" + this.sid + "&ksbmbh=" + ksbmbh;

        // this.$router.push({path:'/admin/examEnrollMould',query:{sid:this.sid,ksbmbh:ksbmbh}})
      },
      getflmc() {
        let val = this.examForm.flbm
        let obj = this.secondList.find(k => {
          return k.flbm === val
        })
        this.examForm.flmc = obj.flmc
        this.examForm.ksjc = obj.mcsx
        this.flbm = val


      },
      // 模板
      check(mc, dm, panelType = 0) {
        if (mc != "ksxz" && dm != "ksxzh") {
          this.showCheckBox = false
        } else {
          this.showCheckBox = true
        }
        this.mc = mc;
        this.dm = dm;
        this.panelType = panelType
        this.showmodal = true
        this.getMouldList(this.flbm)
        this.chooseModel = {}

      },
      addFunMulte() {

        let box = this.checkBox
        let str = ''
        let sid = ''
        let flag
        box.forEach(k => {
          str += k.mbmc + ','
          sid += k.mbbm + ','
          let find = this.tags.find(item => {
            return k.mbbm == item.value
          })
          if (find) {
            flag = 1
          } else {
            this.tags.push({
              label: k.mbmc,
              value: k.mbbm
            })
          }

        })
        if (str) {
          str = str.slice(0, -1)
          sid = sid.slice(0, -1)
        }
        this.examForm[this.mc] = str
        this.examForm[this.dm] = sid
        this.showmodal = false
      },
      closeTags(index, mc, dm) {
        let str = ''
        let sid = ''
        this.tags.splice(index, 1)
        this.tags.forEach(k => {
          str += k.label + ','
          sid += k.value + ','
        })
        if (str) {
          str = str.slice(0, -1)
          sid = sid.slice(0, -1)
        }
        this.examForm[mc] = str
        this.examForm[dm] = sid

      },
      // 添加模板相关
      addFun() {
        if (this.panelType) {
          this.addFunMulte();
          return
        }
        this.showmodal = false
        this.examForm[this.dm] = this.chooseModel.mbbm
        this.examForm[this.mc] = this.chooseModel.mbmc

      },

      // 公告
      check2(mc, dm) {
        this.mc = mc;
        this.dm = dm;
        this.showmodal2 = true
        this.pageData2.keyword = ""
        this.chooseNews = ""
        this.getNewsList()


      },
      // 添加公告相关
      addFun2() {
        this.showmodal2 = false
        this.examForm[this.dm] = this.chooseNews.newsId
        this.examForm[this.mc] = this.chooseNews.newsTitle
      },
      getlbmc() {
        let val = this.examForm.lbbm
        let obj = this.classList.find(k => {
          return k.lbbm === val
        })
        this.examForm.lbmc = obj.lbmc
        this.getSecondList({
          lbbm: val
        });
      },
      // 二级分类
      getSecondList(params) {
        SecondEasyList(params).then(res => {
          if (res.status) {
            this.secondList = res.data
          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getMouldList()
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getMouldList()
      },
      submit() {
        if (this.sid) {
          this.editExamForm()
        } else {
          this.addExamForm()
        }

      },
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      changType() {
        if (this.examForm.kslxmc == "滚动考试") {
          this.examForm.sfgdks = true
        } else {
          this.examForm.sfgdks = false
        }
        if (this.examForm.kslxmc == "其他申请报名") {
          this.examForm.zpysyq = 4
        }
      },
      getgllbmc() {
        let val = this.addForm.gllbdm
        let obj = this.secondList.find(k => {
          return k.flbm === val
        })
        this.addForm.gllbmc = obj.flmc

      },
      showRules() {
        this.$refs.printRules.check('zkzsmmc', 'zkzsmh')
      },
      addMb(str, sid, mc, dm) {
        this.examForm[mc] = str
        this.examForm[dm] = sid
      },
    },
    mounted() {
      this.getClassListPage()
      this.getSecondList()
      this.getYear()
      if (this.ksbmbh) {
        this.getDetail(this.ksbmbh);
        $("#kslb").attr("disabled", "disabled")
        $("#ejfl").attr("disabled", "disabled")
        $("#kmxzfs").attr("disabled", "disabled")
      }
      this.examForm.ksnf = new Date().getFullYear();
      this.UEditorConfig.initialFrameHeight = 350; //定义富文本高度
    },
    created() {
      this.ksbmbh = this.$route.query["ksbmbh"] ? this.$route.query["ksbmbh"] : "";
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :items="items" />
    <ExamHeader :ksbmbh="ksbmbh" ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <div class="outerCotent">
              <el-form ref="examForm" :model="examForm" :rules="rules">
                <input type="hidden" class="msgValInput" value="" name="sid" v-model="examForm.ksbmbh">
                <input type="hidden" class="msgValInput" value="" v-model="examForm.sid">
                <div class="msgContent flexList fs-xs">
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">考试类别</div>
                    <div class="msgVal selectShow flexList">
                      <el-form-item>
                        <select name="lbbm" class="form-control msgValInput " id="kslb" v-model="examForm.lbbm"
                          @change="getlbmc">
                          <option value="" disabled>请选择所属类别</option>
                          <option :value="item.lbbm" v-for="(item,index) in classList" :key="index">{{item.lbmc}}
                          </option>
                        </select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">二级分类</div>
                    <div class="msgVal selectShow flexList">
                      <el-form-item>
                        <select name="" class="form-control msgValInput" id="ejfl" v-model="examForm.flbm"
                          @change="getflmc">
                          <option value="" disabled>请选择分类</option>
                          <option :value="item.flbm" v-for="(item,index) in secondList" :key="index">{{item.flmc}}
                          </option>
                        </select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">考试名称</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <input class="msgValInput form-control " value="" v-model="examForm.ksmc">
                      </el-form-item>
                    </div>
                  </div>


                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">考试简称</div>
                    <div class="msgVal  flexList">
                      <el-form-item prop="ksjc">
                        <el-input value="" disabled v-model="examForm.ksjc"></el-input>
                      </el-form-item>
                    </div>
                  </div>


                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">考试年份</div>
                    <div class="msgVal selectShow flexList">
                      <el-form-item prop="ksnf">
                        <select class="form-control  msgValInput" v-model="examForm.ksnf">
                          <option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                          </option>
                        </select>
                      </el-form-item>

                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">隶属考试信息</div>
                    <div class="msgVal selectShow flexList">
                      <el-form-item>
                        <select name="" class="form-control  msgValInput" v-model="examForm.lsksxxmc">
                          <option value="报名安排">报名安排</option>
                          <option value="2021">
                            2021
                          </option>
                          <option value="2020">2020</option>
                        </select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">考试类型</div>
                    <div class="msgVal selectShow flexList">
                      <el-form-item>
                        <select name="" class="form-control  msgValInput" v-model="examForm.kslxmc" @change="changType">
                          <option value="普通">普通</option>
                          <option value="中直公务员考试">
                            中直公务员考试
                          </option>
                          <option value="特岗计划考试">特岗计划考试</option>
                          <option value="滚动考试">滚动考试</option>
                          <option value="全国计算机考试">全国计算机考试</option>
                          <option value="其他申请报名">其他申请报名</option>

                        </select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel" prop="kmxzfs">
                    <div class="msgName">报名选择方式</div>
                    <div class="msgVal selectShow flexList">
                      <el-form-item>
                        <select name="" class="form-control  msgValInput" id="kmxzfs" v-model="examForm.kmxzfs">
                          <option value="1">专业方式
                          </option>
                          <option value="2">
                            职位方式
                          </option>
                        </select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-100">
                    <div class="flexList w-50 msgLabel">
                      <div class="msgName"><span v-if="examForm.kmxzfs==2">报考单位</span>

                        <span v-else>报考考区</span>必填
                      </div>
                      <div class="msgVal  flexList">
                        <el-form-item>
                          <div class="frame-wrap">
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="bkdw1" class="custom-control-input" v-model="examForm.bkdwbt"
                                value=true>
                              <label class="custom-control-label" style="line-height:19px" for="bkdw1">是</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="bkdw2" class="custom-control-input " v-model="examForm.bkdwbt"
                                value=false>
                              <label class="custom-control-label" style="line-height:19px" for="bkdw2">否</label>
                            </div>
                          </div>
                        </el-form-item>

                      </div>
                    </div>
                    <div class="flexList w-50 msgLabel">
                      <div class="msgName"><span v-if="examForm.kmxzfs==2">报考职位</span>
                        <span v-else>报考专业</span>必填
                      </div>
                      <div class="msgVal  flexList">
                        <el-form-item>
                          <div class="frame-wrap">
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="bkzw1" class="custom-control-input" v-model="examForm.bkzwbt"
                                value=true>
                              <label class="custom-control-label" style="line-height:19px" for="bkzw1">是</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="bkzw2" class="custom-control-input " v-model="examForm.bkzwbt"
                                value=false>
                              <label class="custom-control-label" style="line-height:19px" for="bkzw2">否</label>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                  </div>


                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">是否允许考生上传照片</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sczp1" class="custom-control-input " v-model="examForm.sfsczp"
                              value=true>
                            <label class="custom-control-label" style="line-height:19px" for="sczp1">允许</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sczp2" class="custom-control-input" v-model="examForm.sfsczp"
                              value=false>
                            <label class="custom-control-label" style="line-height:19px" for="sczp2">不允许</label>
                          </div>

                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">照片样式要求</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="zpysyq1" class="custom-control-input" v-model="examForm.zpysyq"
                              value="1">
                            <label class="custom-control-label" style="line-height:19px" for="zpysyq1">白底</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="zpysyq2" class="custom-control-input " v-model="examForm.zpysyq"
                              value="2">
                            <label class="custom-control-label" style="line-height:19px" for="zpysyq2">蓝底</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="zpysyq3" class="custom-control-input " v-model="examForm.zpysyq"
                              value="3">
                            <label class="custom-control-label" style="line-height:19px" for="zpysyq3">红底</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="zpysyq4" class="custom-control-input " v-model="examForm.zpysyq"
                              value="4">
                            <label class="custom-control-label" style="line-height:19px" for="zpysyq4">不需要上传照片</label>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">是否滚动考试</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="gdks1" class="custom-control-input" v-model="examForm.sfgdks"
                              value=true>
                            <label class="custom-control-label" style="line-height:19px" for="gdks1">是</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="gdks2" class="custom-control-input " v-model="examForm.sfgdks"
                              value=false>
                            <label class="custom-control-label" style="line-height:19px" for="gdks2">否</label>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">是否减免考试</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="jmks1" class="custom-control-input" v-model="examForm.sfjmks"
                              value=true>
                            <label class="custom-control-label" style="line-height:19px" for="jmks1">是</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="jmks2" class="custom-control-input " v-model="examForm.sfjmks"
                              value=false>
                            <label class="custom-control-label" style="line-height:19px" for="jmks2">否</label>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">允许重复报名</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="yxcfbm1" class="custom-control-input" v-model="examForm.yxcbm"
                              value=false>
                            <label class="custom-control-label" style="line-height:19px" for="yxcfbm1">不允许</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="yxcfbm2" class="custom-control-input " v-model="examForm.yxcbm"
                              value=true>
                            <label class="custom-control-label" style="line-height:19px" for="yxcfbm2">允许</label>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">协议单位</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <input class="msgValInput form-control " v-model="examForm.xydw" value="">
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel" style="height: 50px;">
                    <div class="msgName">考生须知</div>
                    <div class="msgVal flexList">
                      <el-form-item>
                        <div class="flexList">
                          <div class="mr-2" style="max-width: 80%;overflow-x: auto;" v-show="examForm.ksxz">
                            <el-tag v-for="(tag,index) in tags" :key="index" v-model="examForm.ksxz" closable
                              class="mr-1" type="warning" @close="closeTags(index, 'ksxz', 'ksxzh')">
                              {{tag.label}}
                            </el-tag>

                          </div>

                          <button type="button" class="btn btn-outline-info h30 " @click="check('ksxz','ksxzh',1)">
                            选择
                          </button>
                        </div>



                      </el-form-item>


                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel" style="height: 50px;">
                    <div class="msgName">考生承诺书</div>
                    <div class="msgVal flexList">
                      <el-form-item>
                        <input class=" msgValInput  mr-2" disabled v-model="examForm.kscnsmc" />
                        <label type="button" class="btn btn-outline-info h30 mb-0" for="customFile"
                          @click="check('kscnsmc','kscnsh')">
                          选择
                        </label>
                      </el-form-item>


                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">专业级别判断</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="zyjb1" class="custom-control-input" v-model="examForm.zyjbpd"
                              value="1">
                            <label class="custom-control-label" style="line-height:19px" for="zyjb1">是</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="zyjb2" class="custom-control-input " v-model="examForm.zyjbpd"
                              value="0">
                            <label class="custom-control-label" style="line-height:19px" for="zyjb2">否</label>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">照片自动审核</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="zpzdsh1" class="custom-control-input" v-model="examForm.zpzdsh"
                              value=true>
                            <label class="custom-control-label" style="line-height:19px" for="zpzdsh1">是</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="zpzdsh2" class="custom-control-input " v-model="examForm.zpzdsh"
                              value=false>
                            <label class="custom-control-label" style="line-height:19px" for="zpzdsh2">否</label>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">学历自动审核</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="xlzdsh1" class="custom-control-input" v-model="examForm.xlzdsh"
                              value="1">
                            <label class="custom-control-label" style="line-height:19px" for="xlzdsh1">必须验证</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="xlzdsh2" class="custom-control-input " v-model="examForm.xlzdsh"
                              value="0">
                            <label class="custom-control-label" style="line-height:19px" for="xlzdsh2">可验证</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="xlzdsh3" class="custom-control-input" v-model="examForm.xlzdsh"
                              value="2">
                            <label class="custom-control-label" style="line-height:19px" for="xlzdsh3">不验证</label>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">是否采用告知承诺制</div>
                    <div class="msgVal  flexList">
                      <el-form-item>
                        <div class="frame-wrap">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sfcygzcn1" class="custom-control-input" v-model="examForm.sfgzcn"
                              value=true>
                            <label class="custom-control-label" style="line-height:19px" for="sfcygzcn1">是</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sfcygzcn2" class="custom-control-input " v-model="examForm.sfgzcn"
                              value=false>
                            <label class="custom-control-label" style="line-height:19px" for="sfcygzcn2">否</label>
                          </div>

                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">告知通知书</div>
                    <div class="msgVal flexList">
                      <el-form-item>
                        <input class=" msgValInput  mr-2" disabled v-model="examForm.gzcntzmc" />
                        <label type="button" class="btn btn-outline-info h30 mb-0" @click="check('gzcntzmc','gzcntzh')">
                          选择
                        </label>
                      </el-form-item>


                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">报名通知</div>
                    <div class="msgVal flexList">
                      <el-form-item>
                        <el-input v-model="examForm.bmtzmc"></el-input>
                        <label type="button" class="btn btn-outline-info h30 mb-0" @click="check2('bmtzmc','bmtzh')">
                          选择
                        </label>
                      </el-form-item>


                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">计划详情</div>
                    <div class="msgVal flexList">
                      <el-form-item>
                        <input class=" msgValInput  mr-2" disabled v-model="examForm.jhxqmc" />
                        <label type="button" class="btn btn-outline-info h30 mb-0" @click="check2('jhxqmc','jhxqh')">
                          选择
                        </label>
                      </el-form-item>


                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">报名表说明</div>
                    <div class="msgVal flexList">
                      <el-form-item>
                        <input class=" msgValInput  mr-2" disabled v-model="examForm.bmbsmmc" />
                        <label type="button" class="btn btn-outline-info h30 mb-0" @click="check('bmbsmmc','bmbsmh')">
                          选择
                        </label>
                      </el-form-item>


                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">准考证说明</div>
                    <div class="msgVal flexList">
                      <el-form-item>
                        <input class=" msgValInput  mr-2" disabled v-model="examForm.zkzsmmc" />
                        <label type="button" class="btn btn-outline-info h30 mb-0" @click="showRules()">
                          选择
                        </label>
                      </el-form-item>


                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">确认表注意事项</div>
                    <div class="msgVal flexList">
                      <el-form-item>
                        <input class=" msgValInput  mr-2" disabled v-model="examForm.qrbzysxmc" />
                        <label type="button" class="btn btn-outline-info h30 mb-0"
                          @click="check('qrbzysxmc','qrbzysxh')">
                          选择
                        </label>
                      </el-form-item>


                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel" style="height: 50px;">
                    <div class="msgName"></div>
                    <div class="msgVal flexList">
                      <button type="button" class="btn btn-info h35 mr-3  sub-btn" @click="submit">提交信息，进入下一步</button>

                    </div>
                  </div>

                </div>
              </el-form>
            </div>


          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 信息模板 -->

    <b-modal id="addMould" v-model="showmodal" centered title="信息模板选择" size="lg" title-class="font-18" hide-footer>
      <div>
        <b-button variant="outline-info" class="flexList mb-2 condition h30" @click="addNew"><i
            class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加新模板</b-button>
        <table class="table  light-table table-hover  table-bordered text-center">
          <thead class="thead-light">
            <tr>
              <th>选择</th>
              <th style="width: 10%;">序号</th>
              <th>标题</th>
              <th>模板类别</th>
              <th>添加时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in mouldList" :key="index">
              <td>
                <input type="radio" :value="item" v-model="chooseModel" v-if="!showCheckBox">
                <input type="checkbox" :value="item" name="121323132" v-model="checkBox" v-else>
              </td>
              <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
              <td>{{item.mbmc}}</td>
              <td>{{item.mblb}}</td>
              <td> {{ item.createTime }}</td>
            </tr>
          </tbody>
        </table>
        <div class="mb-2 text-right">
          <el-pagination @size-change="handleSizeChange" background small @current-change="handleCurrentChange"
            :current-page="pageData.pageNum" :page-size="pageData.pageSize" layout="prev, pager, next"
            :total="pageData.total">
          </el-pagination>
        </div>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="addFun()">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 通知公告选择 -->
    <b-modal id="addNotice" v-model="showmodal2" size="lg" centered title="通知公告选择" title-class="font-18" hide-footer>
      <form>
        <div>
          <div class="flexList mb-2"><a href="/admin/announcePublish" target="_blank">
              <b-button variant="outline-info" class="flexList mr-2 condition h30"><i
                  class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加新公告</b-button>
            </a>
            <input placeholder="请输入公告标题搜索" v-model="pageData2.keyword" class=" w-30  form-control h30 mr-2" type="text"
              clearable>
            <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
          </div>


          <table class="table  light-table table-hover  table-bordered text-center">
            <thead class="thead-light">
              <tr>
                <th style="width: 5%;">选择</th>
                <th style="width: 5%;">序号</th>
                <th>通知公告标题</th>
                <th>公告类别</th>
                <th>添加时间</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in newsList" :key="index">
                <td><input type="radio" :value="item" v-model="chooseNews"></td>
                <td>{{index+1+(pageData2.pageNum-1)*pageData2.pageSize}}</td>
                <td>{{item.newsTitle}}</td>
                <td>{{item.bigType}}-{{item.smallType}}</td>
                <td>{{item.createTime}}</td>
              </tr>
            </tbody>
          </table>

          <div class="mb-2 text-right">
            <el-pagination @size-change="handleSizeChange" background small @current-change="handleCurrentChange"
              :current-page.sync="pageData2.pageNum" :page-size="pageData2.pageSize" layout="prev, pager, next"
              :total="pageData2.total">
            </el-pagination>
          </div>
        </div>
        <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
            @click="addFun2($event)">确定</button>
          <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal2 = false">取消</button>
        </div>
      </form>


    </b-modal>
    <!-- 添加模板 -->
    <b-modal id="addMould" v-model="showmodal3" centered title="添加新模板" size="lg" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">

          <div class="grey-time mb-3">说明：模板添加后在报名设置时可以选用。</div>
          <el-form-item>
            <div class="d-flex check-distri mb-3">
              <span>模板类型：</span>
              <div class=" col-sm-10 p-0 flexList">
                <select name="" class="form-control form-select  w-100" v-model="addForm.mblb">
                  <option value="" disabled>请选择模板类型</option>
                  <option value="报名协议">报名协议</option>
                  <option value="承诺书">承诺书</option>
                  <option value="注意事项">注意事项</option>
                  <option value="其他">其他</option>
                </select>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3" v-if="addForm.mblb=='其他'">
              <span>模板类别：</span>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" maxlength="10" value="" v-model="addForm.mblb" placeholder="请输入其他模板类型"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3">
              <span>分类关联：</span>
              <div class=" col-sm-10 p-0 flexList">
                <select name="" class="form-control form-select  w-45" v-model="addForm.glfs">
                  <option value="" disabled>所属考试类别，非必须</option>
                  <option value="二级类别">二级类别</option>
                  <option value="无">无</option>
                </select>
                <span class="ml-2">所属类别：</span>
                <select name="" class="form-control form-select  w-45" v-model="addForm.gllbdm" @change="getgllbmc"
                  v-if="addForm.glfs!='无'">
                  <option value="" disabled>所属考试类别，非必须</option>
                  <option :value="item.flbm" v-for="(item,index) in secondList" :key="index">{{item.flmc}}</option>
                </select>
                <select name="" class="form-control form-select  w-45" v-model="addForm.gllbdm" v-else>
                  <option value="" disabled>所属考试类别，非必须</option>
                  <option value="无">无</option>
                </select>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3">
              <span>模板标题:</span>
              <div class="col-sm-10  p-0 flexList">
                <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.mbmc" placeholder="请输入标题"
                  class="form-control w-100 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex mb-3 w-95">
              <div class="content-mould">
                <!-- <ckeditor :editor="editor" v-model="addForm.mbnr" ></ckeditor> -->
                <vue-ueditor-wrap v-model="addForm.mbnr" :config="UEditorConfig"></vue-ueditor-wrap>
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" name="exampleRadios" id="exampleRadios1"
                    value=true v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadios1"
                    class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="exampleRadios" id="exampleRadios1" value=fasle
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadio1"
                    class="form-check-label">不启用</label></div>

              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>


      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="addMould()">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal3 = false">取消</button>
      </div>
    </b-modal>

    <PrintRules :flbm="flbm" @addFun="addMb" :examForm="examForm" ref="printRules"></PrintRules>
    <!-- 添加公告 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
